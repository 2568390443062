import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

const Li = styled.li`
  display: inline-block;
  margin-right: 1rem;
`

const H3 = styled.h3`
  margin-bottom: ${rhythm(2)};
  display: inline;
  font-style: normal;
`

const Ul = styled.ul`
  list-style: none;
  float: right;
`

const ListLink = props => (
  <Li>
    <Link to={props.to}>{props.children}</Link>
  </Li>
)

const Header = styled.header`
  margin-bottom: 1.5rem;
`

const Navigation = ({ title }) => (
  <Header>
    <Link to="/">
      <H3>{title}</H3>
    </Link>
    <Ul>
      <ListLink to="/">Home</ListLink>
      <ListLink to="/about/">About</ListLink>
      <ListLink to="/contact/">Contact</ListLink>
    </Ul>
  </Header>
)

const Footer = () => {
  const copyrightYear = () => {
    const startYear = "2020"
    const currentYear = new Date().getFullYear().toString()
    return currentYear === startYear ? startYear : `${startYear}-${currentYear}`
  }

  return (
    <footer>
      <p>&copy; Copyright {copyrightYear()} schubam</p>
    </footer>
  )
}

const Div = styled.div`
  margin: 0 auto;
  max-width: 700px;
  padding: ${rhythm(2)};
  padding-top: ${rhythm(1.5)};
`

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <Div>
      <Navigation title={data.site.siteMetadata.title} />
      {children}
      <Footer />
    </Div>
  )
}

export default Layout
